<template>
  <div>
    <Navbar page="Corrigir avaliações" link="/treinamentos" nameLink="Gestão de Treinamentos" nameLink_2="Avaliações" link_2="/avaliacoes"  />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
    <div class="my-3 py-3 px-4 border rounded-sm w-96">
      <p v-if="treinamento" class="text-base font-medium text-gray-700">Média avaliações: {{treinamento && treinamento.media && treinamento.media ? parseFloat(treinamento.media).toFixed(1) :"0.0"}} </p>
      <p class="text-base font-medium text-gray-700">Média nota facilitador: {{ mediaNotaFacilitador ? mediaNotaFacilitador : 0  }} </p>
    </div>
    <select v-model="sort" class="my-3 w-full md:w-115 py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
      <option default value="Ordenando por esperando correção">Ordenando por esperando correção</option>
      <option value="Ordenando por nota da prova">Ordenando por nota da prova</option>
      <option value="Ordenando por data de realização da prova">Ordenando por data de realização da prova</option>
    </select>
    <a v-if="list.length > 0" :href="`${url_api}/v1/${route}/provas/download/${$route.params.id}?token=${$store.state.token}&sort=${sort}`" type="button" role="button" target= "_blank" rel="noreferrer" class="md:mx-4 inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-400">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
      </svg> 
      Download do resultado das provas
    </a>
    <a v-if="list.length > 0" :href="`${url_api}/v1/${route}/provas/download/${$route.params.id}?token=${$store.state.token}&sort=${sort}&respostas=1`" type="button" role="button" target= "_blank" rel="noreferrer" class="md:mx-4 inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
      </svg> 
      Download das provas com respostas
    </a>
    <div v-if="list && list.length" class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Nome
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Email
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Nota facilitador
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Data Aplicação
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Nota Final
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Status
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Opções
                      </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="item in list" :key="item._id">
                      <td class="px-2 py-3">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{item.convidado && item.convidado.nome ? item.convidado.nome : ''}}
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{item.convidado && item.convidado.email ? item.convidado.email : ''}}
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            <star-rating v-model="item.notaFacilitador" read-only :star-size="20"></star-rating>
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div v-if="item.dataAplicada" class="flex items-center">
                          <span class="text-sm text-gray-500">
                            {{ item.dataAplicada | moment("DD/MM/YYYY HH:mm") }}
                          </span> 
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div v-if="item.resultado" class="flex items-center">
                          <span class="text-sm font-medium" :class="item.resultado.aprovado ? 'text-green-600' : 'text-red-600'">
                            {{ item.resultado.nota }} pontos / {{ item.resultado.total }} pontos
                          </span> 
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500 font-semibold" :class="item.status === 'Corrigida automaticamente' || item.status === 'Corrigida' ? 'text-green-600': 'text-yellow-600'">
                            {{ item.status ? item.status : ''}}
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <router-link type="button" v-if="item.esperandoCorrecao" :to="`/treinamentos/corrigir/prova/${item._id}`" class="text-center w-44 inline-block text-sm text-white hover:bg-green-700 bg-green-600 rounded py-1 px-4 mt-2 mr-1">
                            Corrigir avaliação
                        </router-link>
                        
                        <span v-else class="inline-block text-sm text-white hover:bg-green-700 bg-green-600 rounded py-1 px-4 mt-2 w-44 text-center mr-1 opacity-25">Avaliação corrigida</span>
                        
                        <span class="text-sm font-medium text-green-600 py-1 mt-2">
                            <router-link :to="`/treinamentos/answer/${item._id}`" type="button" class="text-white text-sm hover:bg-blue-500 bg-blue-700 rounded py-1 px-2 my-1 w-44 text-center">
                              Visualizar prova
                            </router-link>
                        </span> 
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
      <div v-else class="text-base font-medium">
        Nenhuma avalição disponível para corrigir!
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
export default {
  components: {
    StarRating,
  },
  data() {
    return {
      route: 'avaliacoes',
      list: [],
      sort: "Ordenando por esperando correção",
      treinamento: null,
      mediaNotaFacilitador: 0,
      total: 0,
      page: 1,
      perPage: 50,
      query: { page: 1, limit: 50, skip: 0  },
    }
  },
  methods: {
    async start() {
      const _id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';
      const listReq = await this.$http.post(`/v1/${this.route}/listarProvasCorrigir`, { _id, skip: this.query.skip, limit: this.query.limit, sort: this.sort });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
      this.treinamento = listReq.data.treinamento;
      this.mediaNotaFacilitador = listReq.data.mediaNotaFacilitador;
    },
    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;

      this.$router.push({ path: `/treinamentos/corrigir/${this.$route.params.id}`, query: this.query });
    },
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {
    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.$watch('sort', async (sort) => {
      const _id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';
      if(!_id) return;
      const listReq = await this.$http.post(`/v1/${this.route}/listarProvasCorrigir`, { _id, skip: this.query.skip, limit: this.query.limit, sort: sort });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
      this.treinamento = listReq.data.treinamento;
      this.mediaNotaFacilitador = listReq.data.mediaNotaFacilitador;
    });

    this.start();
  },
}
</script>